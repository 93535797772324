<template>
  <div class="body-content">
    <MLMenu/>
    <div class="ml-content">
      <nuxt/>
    </div>
    <MLFooter/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MLMenu from '~/components/mlearn/layout/menu/menu.vue'
import MLFooter from '~/components/mlearn/layout/footer/footer.vue'
export default {
  components: {
    MLMenu,
    MLFooter
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  }
}
</script>
<style lang="scss" scoped>
.body-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .ml-content{
    margin-top: 90px;
    @media (max-width:1366px) {
      margin-top: 80px;
    }
    @media (max-width:576px) {
      margin-top: 60px;
    }
  }
}
</style>
