<template>
    <nuxt/>
<!--    </nuxt>-->
</template>
<script>
// import ModalForget from '~/components/mlearn/modal/forget.vue'
// import ModalLogin from '~/components/mlearn/modal/login.vue'
// import ModalRegister from '~/components/mlearn/modal/register.vue'
export default {
  name: 'DetailLogin'
  // components: {ModalForget, ModalLogin, ModalRegister}
}
</script>
<style lang="scss" scoped>
</style>
