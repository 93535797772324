<template>
  <div class="container wscn-http404">
    <div class="row py-5 align-items-center">
      <div class="col-lg-6">
        <div class="pic-404" v-if="error.statusCode === 404">
          <img class="pic-404__parent" src="~/mlearn/icons/error/404.png" alt="404">
          <img class="pic-404__child left" src="~/mlearn/icons/error/404_cloud.png" alt="404">
          <img class="pic-404__child mid" src="~/mlearn/icons/error/404_cloud.png" alt="404">
          <img class="pic-404__child right" src="~/mlearn/icons/error/404_cloud.png" alt="404">
        </div>
        <div class="pic-404 pic-401" v-else>
          <img src="~/mlearn/icons/error/401.gif" alt="401">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="bullshit__oops font-weight-bold mb-3">
          {{error.title || 'Có lỗi xảy ra!'}}
        </div>
        <template v-if="error.statusCode === 404">
          <div class="bullshit__info mb-1" >
            {{error.message || 'Vui lòng kiểm tra xem URL bạn đã nhập có chính xác không.'}}
          </div>
          <div class="bullshit__info mb-3">
            Nhấp vào nút bên dưới để trở về trang chủ hoặc gửi báo cáo lỗi.
          </div>
        </template>
        <div class="bullshit__info mb-3" v-else>
          Có lỗi xảy ra, vui lòng thử lại sau.
        </div>
        <div class="mt-5">
          <nuxt-link to="/" class="bullshit__return-home pointer text-white">Về trang chủ</nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error']
}
</script>

<style lang="scss" scoped>
.wscn-http404 {
  .pic-404 {
    &.pic-401 {
			text-align: center;
			img {
				width: auto;
				height: auto;
			}
		}
    &__parent {
      width: 100%;
      transform: rotate3d(0, 0, 0, 0deg);
      animation: rotate 4s;
      @keyframes rotate {
        0% {
          transform: rotate3d(25, 13, 5, -90deg);
        }
        100% {
          transform: rotate3d(0, 0, 0, 0deg);
        }
      }
    }
    &__child {
      position: absolute;
      &.left {
        width: 80px;
        top: 17px;
        left: 220px;
        opacity: 0;
        animation: cloudLeft 2s linear 1s infinite;
      }
      &.mid {
        width: 46px;
        top: 10px;
        left: 420px;
        opacity: 0;
        animation: cloudMid 2s linear 1.2s infinite;
      }
      &.right {
        width: 62px;
        top: 100px;
        left: 500px;
        opacity: 0;
        animation: cloudRight 2s linear 1s infinite;
      }
      @keyframes cloudLeft {
        0% {
          top: 17px;
          left: 220px;
          opacity: 0;
        }
        20% {
          top: 33px;
          left: 188px;
          opacity: 1;
        }
        80% {
          top: 81px;
          left: 92px;
          opacity: 1;
        }
        100% {
          top: 97px;
          left: 60px;
          opacity: 0;
        }
      }
      @keyframes cloudMid {
        0% {
          top: 10px;
          left: 420px;
          opacity: 0;
        }
        20% {
          top: 40px;
          left: 360px;
          opacity: 1;
        }
        70% {
          top: 130px;
          left: 180px;
          opacity: 1;
        }
        100% {
          top: 160px;
          left: 120px;
          opacity: 0;
        }
      }
      @keyframes cloudRight {
        0% {
          top: 100px;
          left: 500px;
          opacity: 0;
        }
        20% {
          top: 120px;
          left: 460px;
          opacity: 1;
        }
        80% {
          top: 180px;
          left: 340px;
          opacity: 1;
        }
        100% {
          top: 200px;
          left: 300px;
          opacity: 0;
        }
      }
    }
  }
  .bullshit {
    &__oops {
      font-size: 2rem;
      color: #004390;
      opacity: 0;
      animation: slideUp 0.5s;
      animation-fill-mode: forwards;
    }
    &__headline {
      font-size: 1.25rem;
      opacity: 0;
      animation: slideUp 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
    }
    &__info {
      font-size: 0.875rem;
      color: grey;
      opacity: 0;
      animation: slideUp 0.5s;
      animation-delay: .7s;
      animation-fill-mode: forwards;
    }
    &__return-home {
      width: 110px;
      background: #004390;
      border-radius: 100px;
      padding:12px 35px;
      opacity: 0;
      font-size: 0.875rem;
      animation: slideUp 0.5s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
    }
    @keyframes slideUp {
      0% {
        transform: translateY(60px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}
</style>
