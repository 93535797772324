<template>
  <div id="app" class="min-vh-100">
    <nuxt/>
    <MLFooter/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MLFooter from '~/components/mlearn/layout/footer/footer.vue'
export default {
  components: {
    MLFooter
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  }
}
</script>
